<template lang="pug">
  AppNestedRoutesTabs(:routes="routes")
</template>

<script>
  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    computed: {
      routes() {
        return [
          {
            name: "UserActionLog",
            title: this.$t("logs.user_action_log.title")
          },
          {
            name: "InventoryLog",
            title: this.$t("logs.inventory_log.title")
          }
        ]
      }
    }
  }
</script>

<style lang="sass" scoped></style>
